jQuery(document).ready(function ($) {

  $("#imageGallery").lightSlider({
    gallery: true,
    item: 1,
    loop: true,
    thumbItem: 9,
    slideMargin: 0,
    enableDrag: false,
    currentPagerPosition: "left",
    onSliderLoad: function (el) {
      el.lightGallery({
        selector: "#imageGallery .lslide",
      });
    },
  });

  // Remove widths added by select2 plugin
  $(".select2-container").removeAttr("style");
  $(".select2-search__field").removeAttr("style");
});
